import { useEffect, useState } from 'react';
import {useInterval} from '../../useInterval'
import { TOTP } from 'otpauth';
import { Box, Button, CircularProgress, Typography, ToastStack, Toast } from "@okta/odyssey-react-mui";
import { useTranslation } from 'react-i18next';
import { CopyIcon } from '@okta/odyssey-react-mui/icons';

function OtpCredential({seed}) {
    const { t } = useTranslation();

    const [otp, setOtp] = useState(null);
    const [otpTimestamp,setOtpTimestamp] = useState(null)
    const [otpCode, setOTPCode] = useState(null)

    const [toast,setToast] = useState(false)
    const [progress, setProgress] = useState(0)
    const [otpIntervalFrequency, setOtpIntervalFrequency] = useState(null)


    function handleCopyAction(copyValue) {
        navigator.clipboard.writeText(copyValue)
        setToast(true)
    }

    useEffect(()=>{
        if(seed){
            setOtp(new TOTP({
                algorithm: "SHA1",
                digits: 6,
                period: 30,
                secret: seed,
            }))
            setOtpIntervalFrequency(5000);
        }
    },[seed])

    useInterval(async ()=>{
        let refreshTime;
        if(!otpTimestamp || otpTimestamp.getTime() + 30000 < Date.now()){
            setOTPCode(otp.generate())
            setOtpTimestamp(new Date());
            refreshTime = new Date().getTime() + 30000
        } else {
            refreshTime = otpTimestamp.getTime() + 30000
        }
        const remainingTime = refreshTime - Date.now();
        const percentage = (remainingTime / 30000) * 100
        //the use of max here stops the spinner going to zero of refresh
        setProgress(Math.max(5, Math.round(100-percentage)))
},otpIntervalFrequency)
    
    return (
        <Box sx={{display: 'flex', flexDirection:'row', flexWrap: 'wrap', alignItems:'center', justifyContent:'center', gap:'5px'}}>
        <Typography variant='subordinate'><b>{t('otp')}: </b></Typography>
        <span  className='credential'>
            <Box sx={{display:'flex', alignItems:'center', justifyContent:'center', gap:'5px'}}>
                <CircularProgress value={progress}/><Typography variant='subordinate'>{otpCode}</Typography>{otpCode && <Button variant='floating' onClick={() => handleCopyAction(otpCode)} label={<CopyIcon className='clickable'/>}/>}
            </Box>
        </span>
        <ToastStack>
            <Toast
                isVisible={toast}
                isDismissable={false}
                autoHideDuration={3000}
                onHide={()=>setToast(false)}
                linkText="Info"
                role="status"
                severity="success"
                text={t('copied')}
            />
        </ToastStack>
        </Box>
    );
}

export default OtpCredential;