import { useTranslation } from 'react-i18next';
import OtpCredential from './credentials/OtpCredential';
import { Button, Link, Typography } from '@okta/odyssey-react-mui';
import { ExternalLinkIcon } from '@okta/odyssey-react-mui/icons';
import CommonCredential from './credentials/CommonCredential';
const ResourceComponent = ({ resource }) => {
    const { t } = useTranslation();

    return (
        <div>
            {resource ? (
                <div className='component columnLayout'>
                    <Typography variant='h6'>
                        {resource.label}
                    </Typography>
                    {resource.launchUrl &&
                        <Button
                            variant='tertiary'
                            label={t('launch')}
                            endIcon={<ExternalLinkIcon/>}
                            onClick={() => window.open(resource.launchUrl)}
                            isDisabled={resource.state !== "active"}
                        />
                    }

                    {resource.deploymentData && resource.deploymentData.TenantName &&
                        <CommonCredential title='Tenant' value={resource.deploymentData.TenantName}/>
                    }

                    {resource.credentials &&
                        (
                            <div>
                                {resource.credentials.clientId &&
                                    <div >
                                        <CommonCredential title={t('clientId')} value={resource.credentials.clientId}/>
                                        <CommonCredential title={t('clientSecret')} value={resource.credentials.clientSecret}/>
                                        <div><Link href={resource.credentials.discoveryUrl} variant='monochrome' target='_blank'>{t('metadata')} </Link></div>
                                    </div>
                                }
                                {resource.credentials.username &&
                                    <div>
                                        <CommonCredential title={t('username')} value={resource.credentials.username}/>
                                        <CommonCredential title={t('password')} value={resource.credentials.password}/>
                                        {resource.credentials.otpSeed && 
                                            <OtpCredential seed={resource.credentials.otpSeed}/>
                                        }
                                    </div>
                                }
                                {resource.credentials.apiKey &&
                                    <CommonCredential title={t('apiKey')} value={resource.credentials.apiKey}/>
                                }
                                {resource.deploymentData && resource.settings.showDeploymentData &&
                                    <div>
                                        {Object.keys(resource.deploymentData).map((key) => (
                                            <CommonCredential title={key} value={resource.deploymentData[key]}/>
                                        ))}
                                    </div>
                                }
                            </div>
                        )
                    }
                    {resource.state === "error" &&
                        <span>{t('componentError')}</span>
                    }
                    {resource.state === "queued" &&
                        <span>{t('componentDeploying')}</span>
                    }
                </div>
            )
                : (<div></div>)
            }
        </div>
    )

}
export default ResourceComponent