import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@okta/odyssey-react-mui';
import { ExternalLinkIcon } from '@okta/odyssey-react-mui/icons';

const AppComponent = ({ app }) => {
    const { t } = useTranslation();
    return (
        <div className='component'>
            {app ? (
                <div className='component'>
                    <Typography variant='h6'>{app.label}</Typography>
                    <Button
                        variant='tertiary'
                        label={t('launch')}
                        endIcon={<ExternalLinkIcon/>}
                        onClick={() => window.open(app.link)}
                        isDisabled={app.state !== "active"}
                    />
                    {app.state === "error" &&
                        <span>{t('componentError')}</span>
                    }
                    {app.state === "queued" &&
                        <span>{t('componentDeploying')}</span>
                    }
                </div>
            )
                : (<div></div>)
            }
        </div>
    )

}
export default AppComponent