import { useState } from "react";
import { Typography, Box, Button, ToastStack, Toast } from "@okta/odyssey-react-mui";
import { useTranslation } from 'react-i18next';
import { CopyIcon } from "@okta/odyssey-react-mui/icons";

function CommonCredential({title,value}) {
    const { t } = useTranslation();
    const [toast,setToast] = useState(false)

    function handleCopyAction(copyValue) {
        navigator.clipboard.writeText(copyValue);
        setToast(true);
    }

    return (
        <Box sx={{display: 'flex', flexDirection:'row', flexWrap: 'wrap', alignItems:'center', justifyContent:'center', gap:'5px'}}>
            <Typography variant='subordinate'><b>{title}:</b></Typography>
            <span  className='credential'>
                <Box sx={{display:'flex', alignItems:'center', justifyContent:'center', gap:'5px'}}>
                    <Typography variant='subordinate'>{value}</Typography>
                    <Button variant='floating' label={<CopyIcon/>} onClick={() => handleCopyAction(value)} />
                </Box>
            </span>
            <ToastStack>
                <Toast
                    isVisible={toast}
                    isDismissable={false}
                    autoHideDuration={3000}
                    onHide={()=>setToast(false)}
                    linkText="Info"
                    role="status"
                    severity="success"
                    text={t('copied')}
                />
            </ToastStack>
        </Box>
    );
}

export default CommonCredential;